import { TrackService, TrackTemplate } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';

import {
  AddPhaseOptions,
  Button,
  Loader,
  Plus,
  SubNavbar,
  SubNavLink,
  Track,
  TrackDetails,
  Tracks as TracksPage
} from '../../components';
import { Can, SystemRole, useAuthenticatedUser } from '../../providers';
import { Interviewers } from '../Interviewers';
import { Participants } from '../Participants';
import { TrackInstance } from '../TrackInstance';

export function Tracks() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url}>
        <TracksPage />
      </Route>
      <Route path={`${url}/:id`}>
        <TrackPage />
      </Route>
    </Switch>
  );
}

function TrackPage() {
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const authUser = useAuthenticatedUser();
  const isSuperAdmin = useMemo(
    () => !!authUser.roles.find((r) => [SystemRole.Superadmin.toString()].includes(r.name)),
    [authUser]
  );

  const [track, setTrack] = useState<TrackTemplate>();
  useAsync(
    async () =>
      void setTrack(
        await TrackService.findOne(id, [
          'originTrackTemplate',
          'conference',
          'phases',
          'phases.originPhase',
          'phases.stepTemplates',
          'phases.stepTemplates.prerequisites',
          'phases.stepTemplates.originStepTemplate',
          'phases.trackTemplate'
        ])
      ),
    [id]
  );

  if (!track) {
    return <Loader />;
  }

  return (
    <>
      <TrackDetails track={track} />
      <Can do="update" on="TrackInstance">
        <SubNavbar>
          <SubNavLink exact to={url}>
            Editor
          </SubNavLink>
          <SubNavLink to={`${url}/participants`}>Participants</SubNavLink>
          <SubNavLink to={`${url}/interviewers`}>Interviewer Groups</SubNavLink>
          <div className="flex flex-1">
            <div className="ml-auto">
              <Switch>
                <Route path={`${url}/participants`}>
                  <Can do="create" on="TrackInstance">
                    <Button onClick={() => router.push(`${url}/participants/add`)}>
                      <Plus /> Participant
                    </Button>
                  </Can>
                </Route>
                <Route path={`${url}/interviewers`}>
                  <Button onClick={() => router.push(`${url}/interviewers/add`)}>
                    <Plus /> Group
                  </Button>
                </Route>
                {(isSuperAdmin || track.conference) && (
                  <Route path={url}>
                    <AddPhaseOptions />
                  </Route>
                )}
              </Switch>
            </div>
          </div>
        </SubNavbar>
      </Can>
      <Can do="read" on={track} type="TrackTemplate" loader={() => <Loader />}>
        <Switch>
          <Route path={`${url}/assignments/:id`}>
            <TrackInstance />
          </Route>
          <Route path={`${url}/participants`}>
            <Participants track={track} />
          </Route>
          <Route path={`${url}/interviewers`}>
            <Interviewers track={track} />
          </Route>
          <Route>
            <Track track={track} setTrack={setTrack} />
          </Route>
        </Switch>
      </Can>
    </>
  );
}

export default Tracks;
